import { MetricsElement } from 'react-metrics';
import React from 'react';

export default class PriceSlider extends React.Component {
    constructor(props) {
        super(props);
        this.slidePrice = this.slidePrice.bind(this);
        this.expandMenu = this.expandMenu.bind(this);
        this.updateRange = this.updateRange.bind(this);
        this.state = { expand: true, currentRange: [props.currentMin, props.currentMax] };
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.minPrice != prevProps.minPrice ||
            this.props.maxPrice != prevProps.maxPrice ||
            this.props.currentMin != prevProps.currentMin ||
            this.props.currentMax != prevProps.currentMax
        ) {
            this.setState({
                currentRange: [this.props.currentMin, this.props.currentMax],
            });
        }
    }

    expandMenu() {
        this.setState({ expand: !this.state.expand });
    }

    slidePrice(range) {
        this.setState({ currentRange: range });
    }

    updateRange(range) {
        this.props.updateFacets('price', range);
    }

    render() {
        let { minPrice, maxPrice, category = {} } = this.props;
        let currentMin = this.state.currentRange[0] || minPrice;
        let currentMax = this.state.currentRange[1] || maxPrice;

        let rangeSettings = {
            min: minPrice,
            max: maxPrice,
            allowCross: false,
            onAfterChange: this.updateRange,
            onChange: this.slidePrice,
            value: [currentMin, currentMax],
            handleStyle: [
                { backgroundColor: '#999', borderColor: '#999' },
                { backgroundColor: '#999', borderColor: '#999' },
            ],
            railStyle: { backgroundColor: '#fff', border: '1px solid #ddd' },
            trackStyle: [{ backgroundColor: '#ddd' }],
        };
        return (
            <div className={`price-slider filter-group${this.state.expand ? ' expanded' : ''}`}>
                <h3 className="filter-header" onClick={this.expandMenu}>
                    Price range
                    <span className="arrow"></span>
                </h3>

                <div className="price-slider">
                    <MetricsElement
                        element="div"
                        data-metrics-event-name="searchFilter"
                        data-metrics-event="e_searchFilter"
                        data-metrics-filter-checked={true}
                        data-metrics-filter-categories={category.displayName}
                        data-metrics-filter-value={`price: ${currentMin}-${currentMax}`}
                    ></MetricsElement>
                    <div className="price-slider-label">
                        <div className="slider-label-left">${this.state.currentRange[0] || this.props.minPrice}</div>
                        <div className="slider-label-right">${this.state.currentRange[1] || this.props.maxPrice}</div>
                    </div>
                </div>
            </div>
        );
    }
}
