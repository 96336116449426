import { connect } from 'react-redux';
import{ push } from 'connected-react-router';
import Filters from './filters';

const mapStateToProps = (state, ownProps) => {
    const { productInfo } = state.productData;
    let pg = typeof ownProps.pg === 'object' ? ownProps.pg : (productInfo.productList[ownProps.pg] || {});
    
    let { metaData, products, productsList } = pg;
    products = products || productsList;
    
	return {
        products,
        categories: metaData ? metaData.categories : [],
        location: state.router.location
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
        pushHistory: (pathname, queryString) => {
            dispatch(push({
                pathname: pathname,
                search: queryString
            }));
        }
	}
};

export default connect(
	mapStateToProps,
    mapDispatchToProps
)(Filters);
