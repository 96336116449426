import React from 'react';
import { MetricsElement } from 'react-metrics';
import isEmpty from 'lodash/isEmpty';
import { Dropdown, Typography } from '@hpstellar/core';
import { CheckboxInput } from '@hpstellar/core';

import PriceSlider from './price-slider';
import { Helpers } from '../../core/src/helpers';
import { withError } from '../../shared/components/error-boundary';

import '../css/filters.less';

class Filters extends React.Component {
    static defaultProps = {
        categories: [],
        filtersResults: {},
        updateUrl: true,
        lockFilterGroups: false,
        orientation: 'vertical',
    };

    constructor(props) {
        super(props);
        this.state = { menuExpanded: true };
        this.initialQueryLoaded = false;
        this.toggleMenu = this.toggleMenu.bind(this);
        this.updateFacets = this.updateFacets.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }

    componentDidMount() {
        this.query = {};
        if (this.props.categories.length && this.props.products.length) {
            this.query = this.initialSetup();
            this.initialQueryLoaded = true;
            this.props.handleChange(this.query, true);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (isEmpty(this.props.filtersResults)) {
            if (!this.props.categories.length) {
                this.query = null;
            } else if (!this.query) {
                this.query = this.initialSetup();
                this.updateUrl();
                this.props.handleChange(this.query, true);
            }
        }
        if (prevProps.pg != this.props.pg && !isEmpty(this.props.filtersResults)) {
            this.query = this.buildQuery();
            this.updateUrl();
        }
    }

    initialSetup() {
        const initialQuery = this.props.filters.query;
        const categories = this.props.categories;
        let query = {};
        if (initialQuery && !this.initialQueryLoaded) {
            for (let key of Object.keys(initialQuery)) {
                if (key === 'cat') {
                    query.cat = initialQuery.cat[0];
                } else if (key === 'price') {
                    query.price = initialQuery.price[0].split('-');
                } else {
                    //convert array to string seperated with comma
                    query[key] = initialQuery[key].join(',');
                }
            }
        } else {
            query = { cat: categories[0].id };
        }

        query.sku = this.skuList(this.props.products);
        return query;
    }

    changeCategory(cat) {
        if (cat.id !== this.query.cat) {
            this.query = { cat: cat.id };
            this.query.sku = this.skuList(this.props.products);
            this.fetchFilters(this.query, true);
        }
    }

    clearFilters() {
        if (this.props.customClearFilters) {
            return this.props.customClearFilters();
        }
        let query = {};
        if (this.query.cat) {
            query.cat = this.query.cat;
        }
        if (this.props.products) {
            query.sku = this.skuList(this.props.products);
        }
        this.query = query;
        this.fetchFilters(this.query, true);
    }

    updateUrl() {
        const { location, pushHistory, filters, updateUrl } = this.props;

        if (!updateUrl) {
            return;
        }

        let urlQuery = Helpers.pickBy(this.query, key => key == 'cat' || key == 'price' || key.includes('facets'));
        let newUrl = location.pathname.split('/');
        newUrl.splice(-1, 1);
        newUrl = newUrl.join('/');
        if (urlQuery.price) {
            urlQuery.price = urlQuery.price.join('-');
        }
        let queryString =
            ';' +
            Object.keys(urlQuery)
                .sort()
                .map(key => key + '=' + urlQuery[key])
                .join(';');
        pushHistory(`${newUrl}/${filters.pg}${queryString}`);
    }

    fetchFilters(query, update = true) {
        this.updateUrl(query);
        this.props.handleChange(query, update);
    }

    buildQuery = () => {
        let query = { cat: this.props.filtersResults.cat || this.props.categories[0].id };
        if (this.props.filtersResults.currentRange && this.props.filtersResults.currentRange.length) {
            query.price = this.props.filtersResults.currentRange;
        }
        const { facets } = this.props.filtersResults;
        if (facets) {
            facets.map(facet => {
                const { facetValues } = facet;
                facetValues.map(el => {
                    if (el.selected) {
                        query[`facets:${facet.key}`] = query[`facets:${facet.key}`]
                            ? query[`facets:${facet.key}`] + ',' + el['_id']
                            : el['_id'];
                    }
                });
            });
        }

        query.sku = this.skuList(this.props.products);
        return query;
    };

    toggleMenu() {
        this.setState({ menuExpanded: !this.state.menuExpanded });
    }

    skuList(products) {
        return products.map(product => product.sku).join(',');
    }

    updateFacets(key, value) {
        let query = this.query;

        if (key == 'price') {
            query[key] = value;
        } else if (value.length) {
            query[`facets:${key}`] = value.sort().join(',');
        } else {
            delete query[`facets:${key}`];
        }
        this.fetchFilters(query, false);
    }

    findCat(catId) {
        if (!catId) return this.props.categories[0];
        else {
            return this.props.categories.find(category => catId == category.id) || this.props.categories[0];
        }
    }

    renderCategories() {
        let categories = this.props.categories;
        let menuExpanded = this.state.menuExpanded;

        if (categories.length > 1 && this.query) {
            let catId = this.query.cat;
            let selectedCategory = this.findCat(catId);

            return (
                <div className="categories-wrapper">
                    <div className="filters-heading">
                        <h2>Categories</h2>
                    </div>
                    <div className="categories-main">
                        <div className={`categories-menu${menuExpanded ? ' expanded' : ''}`}>
                            <h3 className="filters-expand" onClick={this.toggleMenu}>
                                <span className="current-cat">{selectedCategory.displayName}</span>
                                <span className="arrow" />
                            </h3>
                            <ul className="categories-list">
                                {categories.map((category, i) => {
                                    let subCat = category.isSubCat ? 'sub-category ' : '';
                                    let selected = catId == category.id ? 'selected' : '';
                                    return (
                                        <MetricsElement
                                            element="li"
                                            key={i}
                                            className={subCat + selected}
                                            onClick={() => this.changeCategory(category)}
                                            data-metrics-event-name="searchFilter"
                                            data-metrics-event="e_searchFilter"
                                            data-metrics-filter-checked={true}
                                            data-metrics-filter-categories="category"
                                            data-metrics-filter-value={category.displayName}
                                        >
                                            {category.displayName}
                                        </MetricsElement>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="clearfix padding-vert" />
                </div>
            );
        }
        return null;
    }

    renderFilters() {
        const {
            filtersResults,
            isV2,
            lockFilterGroups,
            orientation,
            expandMenu,
            activeIndex,
            forceExpand,
            collapseFilters,
        } = this.props;
        const facets = filtersResults.facets;

        return (
            <div>
                <div className={`filters-heading filters-clear clearfix${forceExpand ? ' no-bottom-border' : ''}`}>
                    <h2 className={`${forceExpand ? ' shrink-filters-text float-none' : ''}`}>Filters</h2>
                    <span
                        className={`clear-filters${forceExpand ? ' shrink-clear-all-text float-none' : ''}`}
                        onClick={this.clearFilters}
                    >
                        Clear all
                    </span>
                </div>
                <div className="category-filters clearfix">
                    {facets &&
                        facets.map((facet, i) => {
                            const { displayName, facetValues, key } = facet;
                            return (
                                <Dropdown
                                    variation="form"
                                    color="light"
                                    onChange={() => {}}
                                    customLabel={displayName}
                                    style={{ maxWidth: 258 }}
                                    customOptionsListProps={{
                                        className: 'blogMultiDropdown',
                                    }}
                                    className="filterDropdown"
                                    multiple
                                    options={facetValues.map(val => ({
                                        label: val.displayName,
                                        value: val._id,
                                        count: val.count,
                                    }))}
                                    renderButtonLabel={() => {
                                        const filterCount = Object.entries(
                                            this.props.selectedFacets?.[key] || {},
                                        ).length;
                                        return `${displayName}${filterCount > 0 ? ` (${filterCount})` : ''}`;
                                    }}
                                    renderOption={option => {
                                        const checked = this.props.selectedFacets?.[key]?.[option.value];
                                        return (
                                            <label
                                                className="dropdown-option"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setTimeout(() => {
                                                        option.count > 0
                                                            ? this.props.customFacetUpdate(key, option.value)
                                                            : null;
                                                    }, 0);
                                                }}
                                            >
                                                <CheckboxInput
                                                    className="dropdown-checkbox"
                                                    onChange={e => void 0}
                                                    checked={checked}
                                                    key={checked}
                                                />
                                                <Typography className="dropdown-option-label">
                                                    {option.label}
                                                </Typography>
                                                <Typography className="dropdown-option-counter">
                                                    {option.count}
                                                </Typography>
                                            </label>
                                        );
                                    }}
                                />
                            );
                        })}
                </div>
            </div>
        );
    }

    render() {
        let { filtersResults, orientation, forceExpand = false, className } = this.props;

        let [minPrice, maxPrice] = filtersResults.priceRange || [];
        let [currentMin, currentMax] = filtersResults.currentRange || [minPrice, maxPrice];
        return (
            <div
                className={`filters tab-content ${this.props.active ? ' active' : ''} ${orientation}${
                    className ? ' ' + className : ''
                }`}
            >
                <div className="filters-inner">
                    {this.renderCategories()}
                    {this.renderFilters()}
                    {minPrice && maxPrice ? (
                        <PriceSlider
                            minPrice={parseInt(minPrice)}
                            maxPrice={parseInt(maxPrice)}
                            currentMin={parseInt(currentMin)}
                            currentMax={parseInt(currentMax)}
                            updateFacets={this.updateFacets}
                            category={this.query && this.query.cat}
                        />
                    ) : null}
                    <div className={`see-results-button${forceExpand ? '' : ' hide'}`}>
                        <div className="see-results-button-inner">See results</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withError(Filters);
